import React, { useEffect, useState } from 'react';
//@ts-ignore
import j from './assets/j.png'
//@ts-ignore
import giphy from './assets/giphy.gif'
//@ts-ignore
import jbg from './assets/j-bg.webp'
import './App.css';
//@ts-ignore
import buttonArrow from './assets/paper-plane-top-solid.svg'
import { Link } from 'react-router-dom';

function App() {
  const [firstMsgGifLoading, setFirstMsgGifLoading] = useState(true)
  const [secondMsgGifLoadingNumber, setSecondMsgGifLoadingNumber] = useState(0)
  const [loading, setLoading] = useState(true)
  window.addEventListener('touchmove', function () { })

  useEffect(() => {
    if (firstMsgGifLoading) {
      setTimeout(() => {
        setFirstMsgGifLoading(false)
        setSecondMsgGifLoadingNumber(1)
      }, 2000)
    }
  })
  useEffect(() => {
    if (!firstMsgGifLoading) {
      setTimeout(() => {
        setSecondMsgGifLoadingNumber(2)
      }, 2000)
    }
  }, [firstMsgGifLoading])
  useEffect(() => {
    if (secondMsgGifLoadingNumber == 2) {
      setTimeout(() => {
        setSecondMsgGifLoadingNumber(3)
      }, 1000)
    }
  }, [secondMsgGifLoadingNumber])
  return (
    <div className='main'>
      <div id='background'>
      </div>
      <div className='body' style={{ zIndex: "2", marginTop: "5vh" }}>
        <div className="toprow">
          <img src={j} alt="" className="jimgleft" />
          <>{firstMsgGifLoading ? (
            <img src={giphy} alt="" className="jimgleft" />) : (
            <div className="firstmsg">
              <p className='firstmsgtext'>yo fam 👋🏼, it's 'J'</p>
            </div>
          )}</>
        </div>
        <div className="secondrow">
          <>{secondMsgGifLoadingNumber == 1 ? (
            <img src={giphy} alt="" className="jimgleft2" />) : (
            (secondMsgGifLoadingNumber == 2 || secondMsgGifLoadingNumber == 3) &&
            (<div className="secondmsg">
              <p className='secondmsgtext'>I'm your money person & I'll do payments for you across the campus! <br /><br />
                PS: you can pay me later 😉</p>
            </div>)

          )}</>
        </div>
        {secondMsgGifLoadingNumber == 3 &&
          <div className="thirdrow">
            <div className="text">
              <p className='firstmsgtextbutton typewriter'>yo J</p>
            </div>
            <a className="button" href="https://api.whatsapp.com/send?phone=919900099558&text=yo%20J">
              <p className='firstmsgtext'>send</p>
              <img src={buttonArrow} alt="" className="buttonArrow" />
            </a>
          </div>
        }
      </div>
      <div className='footer' style={{ zIndex: "2" }}>
        <div className='footerRight' style={{ zIndex: "2" }} >
          <Link to='/terms' className='termslink'>terms</Link>
          <a href="mailto:yo@askj.ai" className='helplink'>help</a>
        </div>
      </div>
      <div className='SEOHiddenHeadings'>
        <div>
          <h1>Introducing 'J': Transforming Campus Payments and Credit Building</h1>
          <h1>How 'J' Works: Cashless Payments and Credit-Building Made Easy</h1>
          <h1>Empower Students with Healthy Credit Habits through 'J'</h1>
          <h1>Campus-Wide Financial Evolution: The 'J' Experience</h1>
        </div>
        <div>
          <h2>Features:</h2>
          <h2>Smart Cashless Payments via WhatsApp with 'J'</h2>
          <h2>Credit-Building Opportunities Tailored for Students</h2>
          <h2>Streamlined Campus Payment System with 'J'</h2>
          <h2>Financial Education and Responsibility with 'J'</h2>
        </div>
        <div>
          <h2>Phased Introduction:</h2>
          <h2>Phased Launch of 'J': Enhancing Campus Finance Responsibly</h2>
          <h2>Phase 1: No Dues Certificate Setup and Parental Approval</h2>
          <h2>Phase 2: Campus-Wide Expansion and Repayment Habit Building</h2>
          <h2>Phase 3: Unified Payment Experience and Merchant Integration</h2>
        </div>
        <div>
          <h2>Benefits</h2>
          <h2>Build Credit History Before Graduation with 'J'</h2>
          <h2>Simplify Transactions and Payments Across Campus</h2>
          <h2>Promote Financial Literacy Among Students</h2>
          <h2>Earn Rewards and Incentives for Responsible Credit Usage</h2>
        </div>
        <div>
          <h2>Testimonials</h2>
          <h2>Student Success Stories with 'J': Building Credit, One Payment at a Time</h2>
          <h2>Positive Feedback from Campuses: 'J' Revolutionizing Financial Habits</h2>
          <h2>Institutional Endorsement: 'J' Enhancing Campus Payment Landscape</h2>
        </div>
        <div>
          <h2>Why Choose 'J'</h2>
          <h2>Leading the Cashless Revolution in Campus Finance</h2>
          <h2>Tailor-Made Solution for Student Credit and Payment Needs</h2>
          <h2>Trusted Partner for Campus-Wide Financial Transformation</h2>
          <h2>Empowering the Next Generation with Sound Financial Skills</h2>
        </div>
        <div>
          <h2>Bringing 'J' to Your Campus: Contact Us Today</h2>
          <h2>Ready for a Financial Transformation? Join Hands with 'J'</h2>
          <h2>Request a Demo and Explore the Future of Campus Finance</h2>
          <h2>Contact Us for a Customized 'J' Implementation Plan</h2>
        </div>
      </div>
    </div>
  );
}

export default App;
