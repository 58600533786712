const myHTML = `<!DOCTYPE html>
<!-- Last Published: Wed Jan 18 2023 17:17:09 GMT+0000 (Coordinated Universal Time) -->
<html>
  <head>
    <meta charset="utf-8" />
    <title>Terms of service | mewt</title>
    <meta content="Terms" property="og:title" />
    <meta content="Terms" property="twitter:title" />
    <meta content="width=device-width, initial-scale=1" name="viewport" />
    <link href="https://mewtmarketing.blob.core.windows.net/mewtmarketing/website_resources/css/main.css" rel="stylesheet" type="text/css" />
    <!--[if lt IE 9
      ]><script
        src="https://cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv.min.js"
        type="text/javascript"
      ></script
    ><![endif]-->
    <script type="text/javascript">
      !(function (o, c) {
        var n = c.documentElement,
          t = ' w-mod-';
        (n.className += t + 'js'),
          ('ontouchstart' in o ||
            (o.DocumentTouch && c instanceof DocumentTouch)) &&
            (n.className += t + 'touch');
      })(window, document);
    </script>
    <link
      rel="apple-touch-icon"
      sizes="57x57"
      href="./favicon/apple-icon-57x57.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="60x60"
      href="./favicon/apple-icon-60x60.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="72x72"
      href="./favicon/apple-icon-72x72.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="76x76"
      href="./favicon/apple-icon-76x76.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="114x114"
      href="./favicon/apple-icon-114x114.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="120x120"
      href="./favicon/apple-icon-120x120.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="144x144"
      href="./favicon/apple-icon-144x144.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="152x152"
      href="./favicon/apple-icon-152x152.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="./favicon/apple-icon-180x180.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href="./favicon/android-icon-192x192.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="./favicon/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="96x96"
      href="./favicon/favicon-96x96.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="./favicon/favicon-16x16.png"
    />
    <link rel="manifest" href="./favicons/manifest.json" />
    <link href='https://fonts.googleapis.com/css?family=Syne' rel='stylesheet'>
    <meta name="msapplication-TileColor" content="#ffffff" />
    <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
    <meta name="theme-color" content="#ffffff" />

    <style>
	  * {
		overflow: scroll !important;
	  }
      ::selection {
        color: #121212;
        background: #276ef1;
      }
      body{
        background-color: #121212;
        color: white;
        font-family: 'Syne';
		overflow: scroll !important;
      }
    </style>
  </head>
  <body>
	<div style="width: 100px; display: 'flex'; padding: 20px;border-radius:50px">
		<img src="https://frontendassetsimages.blob.core.windows.net/mewtfeimages/jlogo.png" />
	</div>
    <div class="container-6 w-container">
      <p>
        <strong>TERMS OF USE <br />‍</strong><br /><p>
						<b>Effective Date:</b> 7th March, 2021<br><br>
					</p>
					<p>
						The website <a href="https://askj.ai">askj.ai</a> and mewt mobile application&nbsp;(jointly
						&ldquo;<strong>App</strong>&rdquo;) are owned and operated by&nbsp;<strong>mewt account private ltd.</strong>&nbsp;(&ldquo;<strong>mewt account private ltd.</strong>&rdquo;)&nbsp;a company
						incorporated under the provisions of the Companies Act, 2013, and having its registered office
						at&nbsp;1st FLOOR,&nbsp;138/12,&nbsp;4TH MAIN ROAD, INDUSTRIAL TOWN, RAJAJINAGAR,&nbsp;BANGALORE,
						KA,&nbsp;560044. To access or use the App on any compatible device you are required to accept
						these Terms and Conditions (&ldquo;<strong>T&amp;C&rdquo;</strong>). By accepting these Terms
						and Conditions, you signify that You have read, understood and agree to be bound by these
						T&amp;C].and any other applicable law, whether or not you are a registered member of the App. As
						used herein, &ldquo;<strong>Users</strong>&rdquo; shall mean anyone who uses or accesses the App
						on any computer, mobile phone, tablet, console or other device (collectively,
						&ldquo;<strong>Device</strong>&rdquo;). Your continued use of the App shall be constituted as
						your acceptance to the T&amp;C, as revised from time to time. If you do not agree with these
						T&amp;C, please do not access and use the App. For the purposes of these T&amp;C,
						&ldquo;<strong>we</strong>&rdquo;, &ldquo;<strong>our</strong>&rdquo; and
						&ldquo;<strong>us</strong>&rdquo; shall mean mewt account private ltd., and/or third-party service
						providers engaged by mewt account private ltd. to render certain services on the App and
						&lsquo;<strong>you</strong>&rsquo; and &lsquo;<strong>your</strong>&rsquo; shall mean a User who
						meets the eligibility criteria set out below.
					</p>
					<h4 class="mt-5 mb-3">
						<strong>1. TERMS AND CONDITIONS </strong>
					</h4>
					<ul class="list-item">
						<li>We reserve the right to update or modify these Terms of Use at any time Your access and
							use of the App following any such change constitutes your agreement to follow and be bound
							by these T&C, as updated or modified. For this reason, we encourage you to review these T&C
							each time you access and use the App. </li>
					</ul>
					<h4 class="mt-5 mb-3"><strong>2. PURPOSE</strong></h4>
					<ul class="list-item">
						<li>
						We are India’s first revolvable line of credit app that offers current account and
							overdraft, in partnership with leading banks & NBFCs. User can avail overdraft, current
							account or credit line by completing the entire application process on the App itself.
							Further, the User can withdraw from the approved credit limit and repay the interest or
							complete amount of the outstanding credit using the App itself.
						</li>
					</ul>
					<h4 class="mt-5 mb-3"><strong>3. ELIGIBILITY</strong></h4>
					<ul class="list-item">
						<li>
							3.1. You represent and warrant that you are competent and eligible to enter into a legally
							binding agreement and be bound by these T&C. You shall not access and use the App if you are
							not competent to contract under the applicable laws, rules and regulations.
						</li>
						<br/>
						<li>
							3.2. For accessing the App and using its services, you, as a User, further represent that
							you are an Indian national having tax residency in India. You also represent and assure that
							you are not a tax resident of any other country.
						</li>
						<br/>
						<li>
							3.3. We maintain the right to provide services to only those users who are – a) competent to
							enter into legally binding contracts, b) have made the representations as provided above, c)
							and also qualify under the internal policy(ies) for the same determined solely by us from
							time to time. We shall have the sole right to change, modify, add or remove, in whole or in
							part, its internal policy(ies), in relation to the provision of the App’s services at any
							time without any prior written notice or intimation to the Users. Further, we shall have the
							right to not entertain any requests in relation to the same, from the users towards such
							services without assigning any reason.
						</li>
						<br/>
						<li>
							3.4. We shall have the right to not process a transaction through the App, if mewt account private ltd.
							in its sole discretion determines that such transaction undertaken by a User is not
							authorized by such User or that the transaction is not genuine or suspicious.
						</li>
						<br/>
						<li>
							3.5. If you represent a company, partnership firm or sole proprietorship, you shall be
							eligible to access the App and avail of the features and facilities on its behalf only if
							you have been duly authorized by way of necessary corporate action, as may be prescribed
							statutorily and/or under the charter documents of such entity and further, if you have
							fulfilled such additional eligibility requirements as mewt account private ltd. may require in
							connection therewith.
						</li>
					</ul>

					<h4 class="mt-5 mb-3"><strong>4. INTELLECTUAL PROPERTY POLICY</strong></h4>
					<ul class="list-item">
						<li>
							4.1. All of the content on the App, including, without limitation, all of the page headers,
							images, illustrations, graphics, audio clips, video clips or text, reports generated,
							trademarks, tradenames (“App Content“), constitute our and our licensors’ intellectual
							property. Copyright laws in all applicable jurisdictions protect the App and the App
							Content.
						</li>
						<br/>
						<li>
							4.2. You may access the App, avail of the features and facilities and utilize the App
							Content for your personal or internal requirements only. You are not entitled to duplicate,
							distribute, create derivative works of, display, or commercially exploit the App Content,
							features or facilities, directly or indirectly, without our prior written permission. If you
							would like to request permission to commercially exploit any particular App Content, you
							could contact us in the manner provided for herein.
						</li>
						<br/>
						<li>
							4.3. mewt account private ltd. and its licensors, if any, are the sole owners of the underlying
							software and source code associated with the App and all the trademarks, copyright and any
							other intellectual property rights of any nature in the App.
						</li>
					</ul>
					<h4 class="mt-5 mb-3"><strong>5. USER ACCOUNT, PASSCODE & SECURITY</strong></h4>
					<ul class="list-item">
						<li>
							5.1. You have an option to set a security passcode on the App once you are approved for a
							credit limit. You are responsible for maintaining the confidentiality of the passcode and
							your account (“Account”) and are fully responsible for all activities that occur under your
							passcode or Account. You agree to: (a) immediately notify us of any unauthorized use of your
							passcode or Account or any other breach of security; and (b) ensure that you exit from your
							Account at the end of each session. We cannot and will not be liable for any loss or damage
							arising from your failure to comply with this Clause 5.
						</li>
						<br/>
						<li>
							5.2. You hereby acknowledge that the deletion of the App from the Device does not constitute
							termination of your Account and agree to undertake the process detailed herein in order to
							complete de-registration. If and when you are desirous of having your name and other details
							removed from the records of mewt account private ltd., immediately upon receiving your written request
							to that effect, mewt account private ltd. shall remove and/delete all such information. You hereby
							acknowledge that the removal of your details from the App does not constitute termination of
							your obligations, if any, to our banking partner who may have approved your credit limit on
							the App.
						</li>
					</ul>
					<h4 class="mt-5 mb-3"><strong>6. USE OF APP – FEATURES AND FACILITIES </strong></h4>
					<ul class="list-item">
						<li>
							6.1. Through the App, mewt account private ltd. assists Users to gain access to certain credit schemes
							from its banking and financial partners.
						</li>
						<br/>
						<li>
							6.2. mewt account private ltd. hereby grants to you a restricted, non-transferable, license to download
							and use the App on a Device, having the specifications provided for in Clause 10 of these
							T&C, which you own or control, to avail of the functions and features in accordance with
							these T&C.
						</li>
						<br/>
						<li>
							6.3. The App provides inter alia a combination of the following facilities to its visitors:

							<p style="padding:0 0 0 20px;">
								6.3.1. Qualification for various financial products;<br>
								6.3.2. Monitoring personal financial transactions and credit history; and<br>
								6.3.3. Personal credit management.
							</p>
						</li>
						<br/>
						<li>
							6.4. Your usage of the App will necessitate you to provide to us certain personal and
							financial information, which shall be collected and maintained as per the terms laid down in
							our privacy policy available at <a
								href="https://mewt.in/mewt-privacy-policy.html">mewt.in/mewt-privacy-policy.html</a>.
						</li>
						<br/>
						<li>
							6.5. We shall not mediate or attempt to get involved in and resolve any disputes or
							disagreements inter se between you and third party/ies.
						</li>
						<br/>
						<li>
							6.6. We will use your name as per the records in NSDL as the name in the application.
						</li>
						<br/>
						<li>
							6.7. mewt account private ltd. may update the App from time to time in order to, inter alia, increase
							its efficiency, optimize user interface and add new features and/or facilities.
						</li>
						<br/>
						<li>
							6.8. We allow our affiliates or third parties to collect certain information when you visit
							our App through the use of cookies or third-party web beacon..
						</li>
						<br/>
						<li>
							6.9. You are required to seek mewt account private ltd.’s permission in case you are writing something
							associated with the App on social media or otherwise. In the event you post any content on
							your own site or any other third party sites about us without our prior written permission,
							we may take strict actions against you.
						</li>
						<br/>
						<li>
							6.10. IN THE EVENT YOU HAVE ANY COMPLAINT AGAINST THE CONTENTS (OR ANY PORTION THEREOF),
							INCLUDING THAT THE CONTENTS THEREIN ARE INCORRECT OR BREACH THE RIGHTS OF A THIRD PARTY,
							mewt account private ltd. SHALL TAKE ALL COMMERCIALLY REASONABLE MEASURES TO ADDRESS THE SAME. HOWEVER,
							mewt account private ltd.’S SOLE OBLIGATION IN THIS REGARD SHALL BE TO REMOVE SUCH CONTENT FROM THE
							APP; AND THE USER SHALL HAVE NO FURTHER CAUSE OF ACTION AGAINST mewt account private ltd..
						</li>
					</ul>
					<h4 class="mt-5 mb-3">
						<strong>7. SERVICE PROVIDERS</strong>
					</h4>
					<ul class="list-item">
						<li>
							7.1. We may employ third-party service providers and individuals due to the following
							reasons:

							<p style="padding-left: 20px;">
								7.1.1. To facilitate our Service;<br>
								7.1.2. To provide the Service on our behalf;<br>
								7.1.3. To perform Service-related services; or<br>
								7.1.4. To assist us in analyzing how our Service is used.
							</p>
						</li>
						<br/>
						<li>
							7.2. You acknowledge that the third-party service provider/agent/agencies will have access
							to your Personal Information on a need basis to assist us in rendering service, and the
							service providers are restricted from using the same for any other reason. The third-party
							service providers are obligated not to disclose or use the information for any other
							purpose.
						</li>
					</ul>
					<h4 class="mt-5 mb-3">
						<strong>8. YOUR RIGHTS & PREFERENCES AS A DATA SUBJECT IN INDIA</strong>
					</h4>
					<ul class="list-item">
						<li>
						These T&C are intended for Users within the territory of India and govern your rights
							as per applicable law within the territory of India. However, in the event you fall under a
							jurisdiction outside the purview of Indian law, we will not be liable for any claim, action
							and/or right initiated/exercised by you as per the extant laws of that jurisdiction.
							Therefore, we request you to kindly use the App accordingly.
						</li>
					</ul>
					<h4 class="mt-5 mb-3"><strong>9. CUSTOMER COMMUNICATIONS</strong></h4>
					<ul class="list-item">
						<li>
							9.1. Accepting these T&C implies your express consent to be contacted by us, our agents,
							representatives, affiliates, or anyone calling on our behalf at any contact number, or
							physical or electronic address provided by you while registering your Account. You further
							agree to us contacting you in any manner, including without limitation, SMS messages
							(including text messages), WhatsApp, calls using pre-recorded messages or artificial voice,
							calls and messages delivered using auto telephone dialing system or an automatic texting
							system, and notifications sent via the App. Automated messages may be played when the
							telephone is answered, whether by you or someone else. In the event that an agent or
							representative calls, he or she may also leave a message on your answering machine, voice
							mail, or send one via SMS.
						</li>
						<br/>
						<li>
							9.2. You certify, warrant and represent that the telephone numbers and/or email addresses
							and any other information that you have provided to us are your own and not someone else’s
							and are true, accurate, current and complete. You represent that you are permitted to
							receive calls at each of the telephone numbers you have provided to us and emails at each of
							the email addresses you have provided us. You agree to notify us whenever you stop using a
							particular telephone number(s) and/or email address(es).
						</li>
					</ul>
					<h4 class="mt-5 mb-3"><strong>10. SYSTEM REQUIREMENTS</strong></h4>
					<ul class="list-item">
						<li>
							In order to use the App, you are required to have a compatible Device with the
							following minimum specifications: Android Operating System version 4.1 (SDK level 16) or
							higher or iOS 9.0 or higher
						</li>
					</ul>
					<h4 class="mt-5 mb-3"><strong>11. USER GUIDELINES</strong></h4>
					<ul class="list-item">
						<li>
							11.1. In consideration of mewt account private ltd. granting you the rights hereunder, you hereby agree
							not to use the App for any purpose that is unlawful under any applicable laws and/or in
							violation of the terms of these T&C and our Privacy Policy. You shall not use the App in any
							manner that could damage, disable, overburden, or impair our server, or any network(s)
							connected to any server, or interfere with any other party’s use and enjoyment of the App.
							You shall not attempt to gain unauthorized access to any functions and features, other user
							accounts, computer systems or networks connected to any server, in any manner, including,
							through hacking, password mining or any other means. You shall not obtain or attempt to
							obtain any materials or information through any means which is not intentionally made
							available through the App.
						</li>
						<br/>
						<li>
							11.2. The App is made available to you for your own personal and non-commercial use alone.
							You shall not allow third parties to: (i) make and/or distribute copies of the App or any
							deliverable generated by the App; (ii) attempt to copy, reproduce, alter, modify and/or
							reverse engineer the App; and/or (iii) create derivative works of the App.
						</li>
						<br/>
						<li>
							11.3. You accept that any and all operations emanating from your Device shall be assumed to
							have been initiated by you.
						</li>
						<br/>
						<li>
							11.4. You shall not copy, reproduce, distribute, or create derivative works of our content
							that is available on the App. Also, you shall not reverse engineer or reverse compile our
							technology that is available on the App, including, without limitation, such Java Applet, as
							may be associated with the App from time to time.
						</li>
						<br/>
						<li>
							11.5. You shall request mewt account private ltd., to block the Account and change the passcode
							immediately for the Account, if your Device has been lost or stolen.
						</li>
						<br/>
						<li>
							11.6. You are responsible for any and all activities that occur in your Account. You agree
							to notify mewt account private ltd. immediately of any unauthorized use of the Account or any other
							breach of security. mewt account private ltd. shall not be liable for any loss to you or your
							organization owing to negligent actions or a failure on your part to inform mewt account private ltd.,
							within a reasonable time, about loss or theft of your Device and/or any unauthorized access
							in your Account, either with or without your knowledge.
						</li>
						<br/>
						<li>
							11.7. You shall be liable for losses incurred by mewt account private ltd. or any other party due to a
							third party’s use of the Account. You shall not use any other person’s account at any time,
							without the permission of the account holder and mewt account private ltd..
						</li>
						<br/>
						<li>
							11.8. mewt account private ltd. shall make all reasonable efforts to ensure that your information is
							kept confidential. However, mewt account private ltd. shall not be responsible for any disclosure or
							leakage of confidential information and/or loss or damage of the Device due to theft,
							negligence or failure on your part to practice safe computing.
						</li>
						<br/>
						<li>
							11.9. You shall ensure that while using the functions and features, all prevailing and
							applicable laws, rules and regulations, shall at all times, be strictly complied with by you
							and mewt account private ltd. shall not be liable in any manner whatsoever for default of any nature,
							by you, regarding the same.
						</li>
						<br/>
						<li>
							11.10. You understand and acknowledge that upon using the App, you authorize us to access
							third party sites designated by you, on your behalf, to retrieve such information requested
							by you, and to register for accounts requested by you. For all purposes hereof, you hereby
							grant us a limited powers and hereby authorize us with the full power of substitution and
							re-substitution, for you and in your name, place and stead, in any and all capacities, to
							access third party internet sites, servers or documents, retrieve information, and use your
							information, all as described above, with the full power and authority to do and perform
							each and every act and thing requisite and necessary to be done in connection with services
							and facilities available on the App, as fully to all intents and purposes as you might or
							could do in person. YOU ACKNOWLEDGE AND AGREE THAT WHEN WE ACCESS AND RETRIEVE INFORMATION
							FROM THIRD PARTY SITES, WE ARE ACTING AS YOUR AGENT, AND NOT THE AGENT OR ON BEHALF OF THE
							THIRD PARTY. You agree that such third parties shall be entitled to rely on the foregoing
							authorization, agency granted by you.
						</li>
						<br/>
						<li>
							11.11. You must notify your bank immediately in the event your credit card is lost, stolen,
							not received, swallowed at an ATM, or is being misused without your permission. Please refer
							the detailed instructions received from your bank for additional precautions to be taken in
							case of loss/ theft/ misuse of your credit card.
						</li>
						<br/>
						<li>
							11.12. You hereby acknowledge and agree that mewt account private ltd. shall not be liable for failure
							of any transaction undertaken for any reason whatsoever including but not limited to
							deficiency of service and/or products delivered as well as technical errors. You further
							acknowledge that mewt account private ltd. shall not be responsible in any manner whatsoever, for any
							loss incurred by you for a failed/ incomplete transaction undertaken by you using Randomity
							Labs services
						</li>
						<br/>
						<li>
							11.13. You hereby agree and express your voluntary, unequivocal and informed consent to your
							personal data or financial information (including such data as may be designated as
							‘personally identifiable data’ under the Information Technology (Reasonable Security
							Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 or other
							applicable law), being processed, disclosed and/or transferred by mewt account private ltd. to
							third-party entities.
						</li>
					</ul>

					<h4 class="mt-5 mb-3"><strong>12. INDEMNIFICATION</strong></h4>
					<ul class="list-item">
						<li>
							12.1. You agree to protect, defend and indemnify us and hold us and our representatives
							harmless from and against any and all claims, damages, costs and expenses, including
							attorneys’ fees, arising from or related to your access and use of the App in violation of
							these T&C and/or your infringement, or infringement by any other user of your Account, of
							any intellectual property or other right of anyone.
						</li>
						<br/>
						<li>
							12.2. The terms of this provision will survive any termination or cancellation of these T&C
							or your use of the App.
						</li>
					</ul>

					<h4 class="mt-5 mb-3"><strong>13. WARRANTIES:</strong></h4>
					<ul class="list-item">
						<li>
							13.1. The App and the functions and features therein are provided on an “as is” and on an
							“as available” basis. We expressly disclaim all warranties of any kind, whether express or
							implied, including, but not limited to the implied warranties of merchantability, fitness
							for a particular purpose and non-infringement.
						</li>
						<br/>
						<li>
							13.2. We shall make reasonable efforts to make available the App and the functions and
							features at all times. However, we make no warranty that the App shall meet your
							requirements, be uninterrupted, timely, secure, and/or error free. Further we do not make
							any warranty as to the results that may be obtained from the use of the functions and
							features or as to the accuracy, reliability and/or quality of the output derived therefrom.
						</li>
						<br/>
						<li>
							13.3. We shall not be liable for the loss and/or damage of the confidential information or
							data of the User arising as a result of an event or a series of related events, that is
							beyond the control of mewt account private ltd., including failures of or problems with the internet or
							part of the internet, attempted hacker attacks, hacker attacks, denial of service attacks
							and/or viruses or other malicious software attacks or infections.
						</li>
						<br/>
						<li>
							13.4. Any material downloaded or otherwise obtained through the App is done at your own
							discretion and risk and you are solely responsible for any damage to your Device or loss of
							data that results from the download of any such material. No advice or information, whether
							oral or written, obtained by you from us through or from the service will create any
							warranty not expressly stated in these T&C.
						</li>
						<br/>
						<li>
							13.5. We shall not be liable for any losses and/or damages which may arise as a result of a
							third party entity’s usage of the USER’s personal data or financial information (including
							such data as may be designated as ‘personally identifiable data’ under the Information
							Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or
							Information) Rules, 2011).
						</li>
					</ul>
					<h4 class="mt-5 mb-3"><strong>14. LIMITATION OF LIABILITY</strong></h4>
					<ul class="list-item">
						<li>
							IN NO EVENT SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL
							OR CONSEQUENTIAL DAMAGES OR FOR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION,
							DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE
							ACCESS, USE OR PERFORMANCE OF THIS APP’S FUNCTIONS AND FEATURES OR FOR INTERRUPTIONS, DELAY,
							ETC., EVEN IF WE WERE ADVISED OF THE POSSIBILITY OF DAMAGES RESULTING FROM THE COST OF
							GETTING SUBSTITUTE FACILITIES ON THE APP, ANY PRODUCTS, DATA, INFORMATION OR SERVICES
							PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE
							APP, UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA STATEMENTS OR
							CONDUCT OF ANYONE ON THE APP, OR INABILITY TO USE THE APP, THE PROVISION OF OR FAILURE TO
							PROVIDE THE FUNCTIONS AND FEATURES, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
							LIABILITY OR OTHERWISE. THIS CLAUSE SHALL SURVIVE IN PERPETUITY
						</li>
					</ul>

					<h4 class="mt-5 mb-3"><strong>15. SEVERABILITY</strong></h4>
					<ul class="list-item">
						<li>
							If any provision of these T&C is held to be illegal, invalid or unenforceable under
							any present or future applicable laws: (a) such provision will be replaced with another,
							which is not prohibited or unenforceable and has, as far as possible, the same legal and
							commercial effect as that which it replaces; and (b) the remaining provisions of the T&C
							will remain in full force and effect and will not be affected by the illegal, invalid, or
							unenforceable provision or by its severance here from.
						</li>
					</ul>
					<h4 class="mt-5 mb-3"><strong>16. TERMINATION</strong></h4>
					<ul class="list-item">
						<li>
							16.1. These Terms and the right to use granted hereunder shall take effect on the date you
							create an Account with the App, and shall continue in effect until termination this
							Agreement in accordance with this section.
						</li>
						<br/>
						<li>
							16.2. We may terminate the use of the App at any time after giving notice of the termination
							to you.
						</li>
						<br/>
						<li>
							16.3. Upon termination, the rights and license granted to you herein shall terminate and you
							must cease all use of the App.
						</li>
					</ul>

					<h4 class="mt-5 mb-3"><strong>17. WAIVER</strong></h4>
					<ul class="list-item">
						<li>
							Any failure on the part of mewt account private ltd. to require performance of any provision of
							these T&C shall not affect its right to full performance thereof at any time thereafter, and
							any waiver by mewt account private ltd. of a breach of any provision hereof shall not constitute a
							waiver of a similar breach in the future or of any other breach.
						</li>
					</ul>

					<h4 class="mt-5 mb-3"><strong>18. ASSIGNMENT</strong></h4>
					<ul class="list-item">
						<li>
							You shall not assign or transfer any rights, obligations, or privileges that you have
							under these T&C, without the prior written consent of mewt account private ltd.. Subject to the
							foregoing, these T&C will be binding on the successors and permitted assignees of Randomity
							Labs. Any assignment or transfer in violation of this clause will be deemed null and void.
						</li>
					</ul>

					<h4 class="mt-5 mb-3"><strong>19. INDEPENDENCE FROM PLATFORMS</strong></h4>
					<ul class="list-item">
						<li>
							19.1. The App is independent of any platform on which it is located. The App is not
							associated, affiliated, sponsored, endorsed or in any way linked to any platform operator,
							including, without limitation, Apple, Google, Android or RIM Blackberry (each being an
							“Operator”).
						</li>
						<br/>
						<li>
							19.2. Your download, installation, access to or use of the App is also bound by the terms
							and conditions of the Operator.
						</li>
						<br/>
						<li>
							19.3. You and mewt account private ltd. acknowledge that these T&C are concluded only between you and
							mewt account private ltd., and not with an Operator, and mewt account private ltd., not those Operators, are
							solely responsible for the App and the content thereof to the extent specified in these T&C
						</li>
						<br/>
						<li>
							19.4. mewt account private ltd. is solely responsible for providing any maintenance and support
							services with respect to the App as required under applicable law. You and mewt account private ltd.
							acknowledge that an Operator has no obligation whatsoever to furnish any maintenance and
							support services with respect to the App.
						</li>
						<br/>
						<li>
							19.5. You and mewt account private ltd. acknowledge that mewt account private ltd., not the relevant Operator, is
							responsible for addressing any User claims or any third party claim relating to the App or
							your possession and/or use of the App, including, but not limited to: (i) any claim that the
							App fails to conform to any applicable legal or regulatory requirement; and (ii) claims
							arising under consumer protection or similar legislation.
						</li>
						<br/>
						<li>
							19.6. You and mewt account private ltd. agree that, in the event of any third party claim that the App
							or your possession and use of the App infringes that third party’s intellectual property
							rights, mewt account private ltd. and not the relevant Operator, will be solely responsible for the
							investigation, defense, settlement and discharge of any such intellectual property
							infringement claim; provided such infringement was caused by mewt account private ltd..
						</li>
						<br/>
						<li>
							19.7. You must comply with any applicable third party terms of agreement when using the App
							(e.g. you must ensure that your use of the App is not in violation of your Device agreement
							or any wireless data service agreement).
						</li>
						<br/>
						<li>
							19.8. You and mewt account private ltd. agree that the relevant Operator, and that Operator’s
							subsidiaries, are third party beneficiaries of these T&C , and that, upon your acceptance of
							these T&C , that Operator will have the right (and will be deemed to have accepted the
							right) to enforce the terms of these T&C against you as a third party beneficiary thereof.
						</li>
					</ul>

					<h4 class="mt-5 mb-3"><strong>20. UPDATES</strong></h4>

					<ul class="list-item">
						<li>
							20.1. mewt account private ltd. reserves the right to update the App, in order to, inter alia, increase
							efficiency, optimize user interface, and add new facilities from time to time. Update
							packages will be sent to you on your Device for download and installation.
						</li>
						<br/>
						<li>
							20.2. You hereby agree to install the updates from time to time and acknowledge that
							mewt account private ltd. will only be able to provide Account support for the App if you ensure
							installation of all updates upon receiving notifications thereof when using the App.
						</li>
					</ul>

					<h4 class="mt-5 mb-3"><strong>21. VALIDITY OF T&C</strong></h4>
					<ul class="list-item">
						<li>
							These T&C shall apply when you complete the authentication process and create an
							account and shall remain valid and binding on you for so long as you maintain the Account.
						</li>
					</ul>

					<h4 class="mt-5 mb-3"><STRONG>22. SECURITY:</STRONG></h4>
					<ul class="list-item">
						<li>
							22.1. You are prohibited from violating or attempting to violate the security of the App,
							including, without limitation:

							<p style="padding-left: 20px;">
								22.1.1. accessing data not intended for you or logging into an account which you are not
								authorized to access; <br>
								22.1.2. attempting to probe, scan or test the vulnerability of a system or network or to
								breach security or authentication measures without proper authorization; or <br>
								22.1.3. interfering with service to any user, host, or network.
							</p>
						</li>
						<br/>
						<li>
							22.2. You shall not misuse this App by intentionally introducing viruses, trojans, worms,
							logic bombs or other materials that are malicious or technologically harmful (together
							"Viruses "). You will not attempt to gain unauthorized access to the App, or any server,
							computer or database connected to the App. You will not attack this App via a
							denial-of-service attack. Further, you shall not interfere with or circumvent any security
							feature of the App or any feature that restricts or enforces limitations on use of or access
							to the App, such as probing or scanning the vulnerability of any system, network or breach.
						</li>
						<br/>
						<li>
							22.3. Should you breach the provisions of this Clause, you will be liable to be prosecuted
							under the Information Technology Act, 2000 and other applicable statutes. We will
							immediately report such breach to the relevant law enforcement authorities and will
							co-operate with such authorities by disclosing your identity to them. In the event of such a
							breach, your rights to use this App will cease immediately.
						</li>
						<br/>
						<li>
							22.4. We will not be liable for any loss or damage caused by a denial-of-service attack or
							Viruses that may infect your computer equipment, computer programs, data or other
							proprietary material due to your use of this App or to your downloading of any material
							posted on it.
						</li>
						<br/>
						<li>
							22.5. You agree to immediately report to us all incidents involving suspected or actual
							unauthorized access, disclosure, alteration, loss, damage, or destruction of data.
						</li>
						<br/>
						<li>
							22.6. You shall not interfere with or disrupt (or attempt to interfere with or disrupt)
							access and enjoyment of the App by other users or any host or network, including, without
							limitation, creating or transmitting unwanted electronic communications such as “spam” to
							other users, overloading, flooding or mail-bombing the App, or scripting the creation of
							content in such a manner as to interfere with or create an undue burden on the App.
						</li>
						<br/>
						<li>
							22.7. All actions performed by any person using your account and password shall be deemed to
							have been committed by you and you shall be liable for the same. We reserve the right to
							suspend/terminate your account at any time if it is found that you have been sharing the
							password with any unauthorized user.
						</li>
						<br/>
						<li>
							22.8. In order to reduce the risk of unauthorized access, a user is logged out from the
							account and will have to enter his details and login afresh, after 3 consecutive incorrect
							login attempts..
						</li>
					</ul>

					<h4 class="mt-5 mb-3"><strong>23. MONITORING:</strong></h4>
					<ul class="list-item">
						<li>
							All electronic communications and content presented and/or passed to us, including
							that presented and/or passed from remote access connections, may be monitored, examined,
							saved, read, transcribed, stored, or retransmitted in the course of daily operations by any
							duly authorized employee or agent of ours in the exercise of their duties, or by law
							enforcement authorities who may be assisting us in investigating possible contravention /
							non-compliance with applicable law. Electronic communications and content may be examined by
							automated means. Further, we have the right to reject, at our sole discretion, from the App
							any electronic communications or content deemed not to be in compliance with the corporate
							policies and procedures of mewt account private ltd..
						</li>
					</ul>

					<h4 class="mt-5 mb-3"><strong>24. GOVERNING LAW AND JUSTIFICATION</strong></h4>
					<ul class="list-item">
						<li>
							These T&C (and by extension, the Privacy Policy) are governed and construed in
							accordance with Indian law. By using the App, you hereby irrevocably consent to the
							exclusive jurisdiction and venue of courts in Bangalore, Karnataka, India, in the event of
							any disputes arising out of or in relation to your access to and use of the App.
						</li>
					</ul>

					<h4 class="mt-5 mb-3"><strong>25. CONTACT INFORMATION</strong></h4>
					<ul class="list-item">
						<li>
							If you have any questions or concerns regarding the Terms of Service or Privacy
							Policy, please feel free to contact us at the following <b>email address:</b> <a
								href="mailto:support@askj.ai">support@askj.ai</a> or at the contact details published on
							the App.
						</li>
					</ul>
<br/>
					<h4 class="mt-5 mb-3"><strong>Contact Information</strong></h4>
					<p><strong>Email: <a href="mailto:support@askj.ai">support@askj.ai</a></strong> </p>
				</div>
    </div>
    <script
      src="https://code.jquery.com/jquery-3.6.1.min.js"
      integrity="sha256-o88AwQnZB+VDvE9tvIXrMQaPlFFSUTR+nldQm1LuPXQ="
      crossorigin="anonymous"
    ></script>

    <script type="text/javascript">
      $(document).ready(function () {
        if (isMobile()) {
          $(window).one('focus', Focus);
        }
      });
      var Focus = function () {
        var $vid = $('#video');
        var src = $vid.attr('src');
        $vid.attr('src', src);
        $(window).one('blur', Blur);
      };
      function isMobile() {
        return (
          (navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i)) != null
        );
      }
      var Blur = function () {
        $(window).one('focus', Focus);
      };
    </script>
  </body>
</html>
`;

export const Terms = () => <div dangerouslySetInnerHTML={{ __html: myHTML }} />;
